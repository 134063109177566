<template>
  <div class="mainwidth">
    <div class="box">
      <!-- <div>授权成功</div> -->
      <p v-if="isFlag">请耐心等待{{ countdown }}秒将会跳转至企业认证页面</p>
      <p v-else>授权失败</p>
    </div>
  </div>
</template>

<script>
import { uploadImg } from "@/api/other/other";
export default {
  data() {
    return {
      countdown: 3,
      isFlag: true,
      // targetUrl: 'https://www.baidu.com' // 跳转目标URL
    };
  },
  computed: {
    // ...mapGetters(["isLogin", "userName", "loginType", "orgName", "orgId"]),
  },
  methods: {
    startCountdown() {
      const url = this.$route.fullPath;
      // let url =
      //   "http://192.168.43.119:8080/#/zfLoginSucess?zn=FSZN91230199MA1BTLYM0F,ww,1d3c1c6a435942459399fef725083b71,ww,44aa7d169fc14d0bb89422a3c0b3a635,ww,07fd06f017f744f8910628947f68024a";
      const regex = /zn=FSZN([^,]+),ww,([^,]+),ww,([^,]+),ww,([^,]+)/;
      const matches = url.match(regex);
      let firstValue = "";
      let secondValue = "";
      let thirdValue = "";
      let fourthValue = "";
      console.log(matches);
      if (matches) {
        // 账户名
        firstValue = matches[1];
        // 密码
        secondValue = matches[2];
        // 企业id
        thirdValue = matches[3];
        // 用户id
        fourthValue = matches[4];
      }
      console.log(firstValue, secondValue, thirdValue, fourthValue);

      this.$nextTick(() => {
        this.$store
          .dispatch("getLogin", {
            KEYDATA: "FSZN" + firstValue + ",ww," + secondValue,
            tm: new Date().getTime(),
            LOGIN_TYPE: "E",
          })
          .then((res) => {
            console.log(res);
            if (res.result === "success") {
              this.$message.success("授权成功");
              this.isFlag = true;
              this.$store.dispatch("setLoginType", { lType: "qiye" });
              this.$store
                .dispatch("getUserInfo", { USERNAME: firstValue })
                .then((res) => {
                  // 获取是否上传4张认证图片
                  uploadImg({ ENTERPRISE_ID: res.ORG_ID }).then((res) => {
                    console.log(res);
                    if (res.errInfo === "success") {
                      console.log("123");
                      this.$store.commit("SET_ISUPLOADIMG", res.flag);
                    }
                  });
                });

              // this.getUserCount();

              const timer = setInterval(() => {
                // 每秒减少倒计时剩余时间
                this.countdown--;

                if (this.countdown === 0) {
                  clearInterval(timer);
                  // 倒计时结束，跳转到目标URL
                  //   window.location.href = this.targetUrl;
                  //跳转到详情页面
                  this.$router.push({
                    path: "/PersonalLayout/EnterpriseCertification",
                  });
                }
              }, 1000);
            } else {
              this.isFlag = false;
              this.$message.error("授权失败");
            }
          });
      });
    },
  },
  mounted() {
    // 在组件挂载后，启动倒计时
    this.startCountdown();
  },
};
</script>

<style lang="less" scoped>
.box {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px 10px;
  background-color: @color-ff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #929292;
  position: relative;
  font-size: @font-xl;
  text-align: center;
  p {
    text-align: center;
    margin-top: 10px;
  }
}
</style>
